import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotationPage = () => (
  <Layout>
    <SEO title="よくある質問" />
    <h1>よくある質問</h1>
    <h2>Q. 使い方がわからない</h2>
    <p>
      A.
      左上の「三」ボタンからメニューを開き「使い方を見る」ボタンを押すと使い方を確認できます。
    </p>
    <h2>Q. メモを追加したい</h2>
    <p>
      A.
      メモ一覧画面で右下のボタンを押すとメモを追加できます。そのまますぐ編集ができます。
    </p>
    <h2>Q. メモを保存したい</h2>
    <p>A. メモは編集するたびに自動で保存されています。</p>
    <h2>Q. メモを消したい</h2>
    <p>
      A.
      メモを長押しすると、メモのメニューが出ますので「メモを削除」を選択してください。
    </p>
    <h2>Q. メモを別なタブに移動したい</h2>
    <p>
      A.
      メモを長押しすると、メモのメニューが出ますので「タブを変更」を選択してください。その後移動先のタブを選択してください。
    </p>
    <h2>Q. タブの追加方法は？</h2>
    <p>A. タブの一番右にある「＋」ボタンでタブを新規追加できます。</p>
    <h2>Q. タブの名前を変えたい</h2>
    <p>
      A.
      名前を変えたいタブを長押しするとタブのメニューが開きますので「タブ前の変更」を選択してください。
    </p>
    <h2>Q. タブの順番を変えたい</h2>
    <p>
      A.
      タブを長押しするとタブのメニューが開きますので「タブの順序変更」を選択してください。その後表示される画面で順番を変更できます。
    </p>
  </Layout>
)

export default NotationPage
